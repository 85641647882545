import './App.css';
import { useCallback, useEffect } from "react";
import { Form, Button, Card, Alert } from "react-bootstrap"
import logo from './images/logo.png';
import axios from 'axios';
import './styles.css';

export const App = () => {

  function timeout(delay: number) {
      return new Promise( res => setTimeout(res, delay) );
  };

  function showLoading() {
    document.getElementById("loaderDiv").classList.add("loader");
  };

  function removeLoading() {
    document.getElementById("loaderDiv").classList.remove("loader");
  };

  const handleSubmit = useCallback(async (e) => {
    e.preventDefault();

    const [userPromptValue] = e.target.elements;
    var tempUserPromptValue = userPromptValue.value;
    //console.log(tempUserPromptValue);

    //mini-dalle 2af375da21c5b824a84e1c459f45b69a117ec8649c2aa974112d7cf1840fc0ce
    //text2image 5c347a4bfa1d4523a58ae614c2194e15f2ae682b57e3797a5bb468920aa70ebf
    //stable diffusion
    var jsonData = {
      version: 'a9758cbfbd5f3c2094457d996681af52552901775aa2d6dd0b17fd15df959bef',
      input: {
          prompt: tempUserPromptValue
      }
    }

    const jsonBody = jsonData;

    const resGetUrl = await axios.post('https://api.replicate.com/v1/predictions', jsonBody, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Token d640cdb04627f249e1350d869a26dde82a5687e7',
        'Access-Control-Allow-Origin': '*', 
        'Access-Control-Allow-Methods': 'GET,POST', 
        'Access-Control-Allow-Headers': 'get,keys,values,Authorization'
      }
    });

    const dataURL = resGetUrl.data;
    // console.log(data);
    var getUrl = dataURL.urls.get;
    showLoading();

    await timeout(10000);

    const resImageOutput = await axios.get(getUrl, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Token d640cdb04627f249e1350d869a26dde82a5687e7',
        'Access-Control-Allow-Origin': '*', 
        'Access-Control-Allow-Methods': 'GET,POST', 
        'Access-Control-Allow-Headers': 'get,keys,values,Authorization'
      }
    });

    const dataImage = resImageOutput.data;
    if (dataImage.output != null){
      removeLoading();
      var output_url = dataImage.output[0];
      //console.log(output_url);
      var elem = document.createElement("img");
      var div = document.getElementById("imageOutput");
      elem.setAttribute("src", output_url);
      elem.setAttribute("height", "100%");
      elem.setAttribute("width", "100%");
      elem.setAttribute("alt", "");
      div.insertBefore(elem, div.firstChild);
      //document.getElementById("imageOutput").appendChild(elem);
    } else {
      //console.log(dataImage);
      var div = document.createElement("textMoment");
      var divImage = document.getElementById("imageOutput");
      div.style.textDecoration = "underline";
      div.style.textDecorationColor = "#000";
      div.innerHTML = " image not loaded please try again ";
      divImage.insertBefore(div, divImage.firstChild);
      //document.getElementById("imageOutput").appendChild(div);
      removeLoading();
    }
    
  }, []);

  return (
    <div>
      <Card>
        <Card.Body className="App-header">
          <img src={logo} className="logo-login" alt="Blueberry logo" />
          <div className="card">
            <form onSubmit={handleSubmit} className="card-form">
              <div className="input">
                <input name="userPromptValue" type="text" className="input-field" required/>
                <label className="input-label">Prompt</label>
              </div>
              <div className="action">
                <button className="action-button" type="submit">Generate image</button>
              </div>
            </form>
            <div id="loaderDiv">
              <div>
              </div>
            </div>            
            <div className="card-info" id="imageOutput"></div>
            <div className="card-info">
              <a href="https://blueberryx.com/pages/privacy-policy" target="_blank">Privacy policy</a>
              <p>Blueberry © 2022 - 🫐</p>
            </div>
          </div>
          </Card.Body>
      </Card>
    </div>
  );
};

export default App;
